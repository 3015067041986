/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Figtree";
  src: url("/assets/fonts/Figtree-VariableFont_wght.ttf");
}

body .app-container {
  font-family: "Figtree", sans-serif;
}

body {
  /*Default the background to the off white color*/
  background-color: #fcfcfc !important;
}

.clickable{
  cursor: pointer;
}

button.cc-btn {
  background-color: #93344e;
  color: white;
  font-weight: bold;
}
button.secondary-btn {
  background-color: white;
  color: #93344e;
  font-weight: bold;
  border:1px solid #93344e;
}

input.form-check-input:checked{
  background-color: #93344e;
  border-color: #93344e;
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all ease-in-out 0.5s;
}
.app-splash-screen {
  background: url("/assets/splash.png");
  background-size: cover;
  position: fixed;
  top: 0;
  left: -1%;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 102%;
  height: 100%;
  z-index: 101;
  transition: left 0.5s;
  filter: blur(5px);
}

.app-label {
  margin-top: 32px;
  color: #fff;
  font-size: 2.5em;
  font-family: "Pacifico", cursive;
}

.app-splash-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-logo {
  background: url("/assets/splash_logo.png");
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  background-repeat: no-repeat;
  max-width: 100%;
  background-position: center;
  background-size: contain;
  width: 200px;
  height: 200px;
  z-index: 200;
  position: fixed;
  top: 35%;
}

.hidden {
  transition: 0.5s;
  display: none;
}

.app-loader {
  background: url("/assets/loading.svg");
  background-repeat: no-repeat;
  max-width: 100%;
  background-position: center;
  background-size: contain;
  width: 80px;
  height: 80px;
  margin-top: 80px;
}

.headerContent a {
  color: white;
}

.mainContent h2 {
  color: var(--Text-text-primary, #1a1a1e);
  font-family: "Figtree", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  margin-top: 24px;
}

.off-white {
  background-color: #fcfcfc;
}

.mainContent {
  padding-left: 24px;
  padding-right: 24px;
  background: #fcfcfc;
}

.card-body .form-group {
  padding-top: 15px;
  position: relative;
}

.eye-svg {
  width: 30px;
  height: 30px;
  filter: invert(46%) sepia(18%) saturate(176%) hue-rotate(201deg)
    brightness(90%) contrast(90%);
  position: absolute;
  right: 10px;
  bottom: 5px;
  padding: 5px;
}

.search-svg {
  width: 30px;
  height: 30px;
  filter: invert(46%) sepia(18%) saturate(176%) hue-rotate(201deg)
    brightness(90%) contrast(90%);
  padding: 5px;
}

.black-svg {
  filter: brightness(0%) contrast(100%);
}

input.form-control::placeholder,
textarea.form-control::placeholder {
  opacity: 0.5;
}

.alert.alert-bar{
  display:flex;
  justify-content: space-between;
  margin-bottom:0px;
}

.close-alert{
  cursor: pointer;
  font-size:20px;
  position: relative;
  top: -10px;
}

.card-item {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px #e4e4e7 solid;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  cursor: pointer;
  margin-top: 15px;
}

.card-item-label {
  text-align: center;
  color: #3f3f46;
  font-size: 14px;
  font-family: Test Söhne;
  font-weight: 400;
  line-height: 16.8px;
  word-wrap: break-word;
  display:flex;
  justify-content: space-between;
  width:100%;
}

.wine-card-item-label{
  text-align: left;
  color: black;
  font-weight: bold;
  font-size: 16px;
  font-family: Test Söhne;
}

.gray{
  color:#70707b;
}

.lowercase{
  text-transform: lowercase;
}

.card-item.gray-card {
  background: #f4f4f5;
  color: black;
  cursor: default;
  padding-top: 4px;
  padding-bottom: 4px;
}

#filter {
  border: none;
}

.wine-count-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-item-icon {
  width: 32px;
  height: 32px;
  position: relative;
}

.storage-count {
  font-size: 26px;
  font-weight: bold;
}

.card-item.filter-btn {
  padding: 3px 10px 3px 3px;
  margin-top: 0px;
}

.dividing-row {
  border: 1px solid #E4E4E7;
  margin-top: 15px;
}

.dividing-row.low-margin{
  margin-top: 5px;
}

//Wines/Card styles used across pages
.notificationBottleCountContainer {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 12px;
  background: #f4f4f5;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

.bottleContainer {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottleCount {
  text-align: right;
  color: black;
  font-size: 12px;
  font-family: Test Söhne;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
}

.bottleIcon {
  width: 16px;
  text-align: center;
  color: #1a1a1e;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}


.card-item.wine-card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: inherit;
  border: none;
}

.wine-image {
  width: 56px;
  height: 76px;
}

.card-item-middle {
  display: flex;
  flex-flow: column;
  align-items: start;
  padding-left: 10px;
}

.wine-color {
  display: flex;
  align-items: center;
}

.color-label {
  color: black;
  padding-left: 10px;
}

.color-icon {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.card-item-in-storage {
  display: flex;
  flex-flow: column;
  align-items: end;
  flex-grow: 1;
}

.volume {
  color: #70707b;
  font-size: 12px;
  font-family: Test Söhne;
  font-weight: 300;
  line-height: 20px;
  word-wrap: break-word;
}

.disabled{
  pointer-events: none;
  opacity:0.5;
}

input.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched, select.ng-invalid.ng-touched {
  border: 2px solid red !important;
}

.error-message {
  color: red;
  font-family: Test Söhne;
  font-size: 12px;
}